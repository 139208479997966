import { Button, Modal } from 'react-bootstrap';

interface ActiveConfirmationModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title: string;
  message: string;
  isLoading?: boolean;
}

const ActiveConfirmationModal = ({
  show,
  handleClose,
  handleConfirm,
  title,
  message,
  isLoading = false
}: ActiveConfirmationModalProps) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
          Cancelar
        </Button>
        <Button variant="success" onClick={handleConfirm} disabled={isLoading}>
          {isLoading ? 'Ativando...' : 'Ativar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActiveConfirmationModal; 