import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import Button from 'components/base/Button';
import { calendarService, NewCalendarData, panelService } from 'services/api';

interface CalendarAddNewModalProps {
  show: boolean;
  handleClose: () => void;
}

const CalendarAddNewModal: React.FC<CalendarAddNewModalProps> = ({ show, handleClose }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [colorId, setColorId] = useState('blue');
  const [isPrivate, setIsPrivate] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [colors, setColors] = useState<{ colorId: string; name: string }[]>([]);

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const colorList = await panelService.listColors();
        setColors(colorList);
      } catch (error) {
        console.error('Erro ao buscar cores:', error);
      }
    };

    fetchColors();
  }, []);

  const handleSave = async () => {
    const newCalendar: NewCalendarData = {
      name,
      description,
      colorId,
      isPrivate,
      isDefault
    };

    try {
      await calendarService.createCalendar(newCalendar);
      handleClose();
    } catch (error) {
      console.error('Erro ao criar calendário:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Novo Calendário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="calendarName">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="calendarDescription">
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="calendarColor">
            <Form.Label>Cor</Form.Label>
            <Form.Control
              as="select"
              value={colorId}
              onChange={(e) => setColorId(e.target.value)}
            >
              {colors.map((color) => (
                <option key={color.colorId} value={color.colorId}>
                  {color.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="calendarPrivate">
            <Form.Check
              type="checkbox"
              label="Privado"
              checked={isPrivate}
              onChange={(e) => setIsPrivate(e.target.checked)}
            />
          </Form.Group>
          <Form.Group controlId="calendarDefault">
            <Form.Check
              type="checkbox"
              label="Padrão"
              checked={isDefault}
              onChange={(e) => setIsDefault(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarAddNewModal;