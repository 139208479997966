import {faFileExport,faFilter,faPlus,faEdit,faTrash,faCheck} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import LeadsTable, { leadsTableColumns } from 'components/tables/LeadsTable';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Col, Row, Spinner, Dropdown } from 'react-bootstrap';
import { customerService } from 'services/api';
import { LeadDataType } from 'data/crm/leadsTableData';
import AddLeadModal from 'components/modules/leads/AddLeadModal';
import DeleteConfirmationModal from 'components/modals/DeleteConfirmationModal';
import ActivateConfirmationModal from 'components/modals/ActivateConfirmationModal';
import AddLeadPJModal from 'components/modules/leads/AddLeadPJModal';

const Leads = () => {
  const [leadsData, setLeadsData] = useState<LeadDataType[]>([]);
  const [openAddLeadModal, setOpenAddLeadModal] = useState(false);
  const [openAddLeadPJModal, setOpenAddLeadPJModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [leadToDelete, setLeadToDelete] = useState<LeadDataType | null>(null);
  const [selectedLeadId, setSelectedLeadId] = useState<string | null>(null);

  const fetchLeads = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await customerService.getCustomers();
      const formattedData = data.map((customer: any) => {
        const emailContact = customer.contacts?.find(
          (contact: any) => contact.contactType?.type.toLowerCase() === 'email'
        );
        const phoneContact = customer.contacts?.find(
          (contact: any) => contact.contactType?.type.toLowerCase() === 'telefone'
        );
        const document = customer.documents?.find(
          (doc: any) => doc.documentType?.type.toLowerCase() === 'cpf'
        );

        return {
          id: customer.customerId,
          customer: {
            avatar: customer.photoUrl || '',
            name: `${customer.firstName} ${customer.lastName}`,
            designation: customer.jobTitle || '-',
            status: {
              label: customer.isActive ? 'Ativo' : 'Inativo',
              type: customer.isActive ? 'success' : 'secondary'
            }
          },
          email: emailContact?.value || '-',
          phone: phoneContact?.value || '-',
          document: document?.value || '-',
          company: customer.company || '-',
          annualRevenue: customer.annualRevenue || '-',
          numberOfEmployees: customer.numberOfEmployees || '-',
          leadSource: customer.leadSource || '-',
          description: customer.description || '-',
          date: new Date(customer.createdAt).toLocaleString() || '-'
        };
      });
      setLeadsData(formattedData);
    } catch (error) {
      console.error('Erro ao buscar leads:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLeads();
  }, [fetchLeads]);

  const table = useAdvanceTable({
    data: leadsData,
    columns: leadsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleEditLeadClick = () => {
    if (selectedLeads.length === 1) {
      // Lógica para editar o lead selecionado
    }
  };

  const handleDeleteLeadClick = () => {
    if (selectedLeads.length === 1) {
      setLeadToDelete(selectedLeads[0].original as LeadDataType);
      setShowDeleteConfirmationModal(true);
    }
  };

  const handleActivateLeadClick = () => {
    if (selectedLeads.length === 1) {
      const selectedLead = selectedLeads[0].original as LeadDataType;
      setSelectedLeadId(selectedLead.id.toString());
      setShowActivateModal(true);
    }
  };

  const handleConfirmDelete = async () => {
    if (leadToDelete) {
      try {
        await customerService.deactivateCustomer(leadToDelete.id.toString());
        await updateLeadsAndResetSelection();
      } catch (error) {
        console.error('Erro ao desativar lead:', error);
      } finally {
        setShowDeleteConfirmationModal(false);
        setLeadToDelete(null);
      }
    }
  };

  const handleConfirmActivate = async () => {
    if (selectedLeadId) {
      try {
        await customerService.activateCustomer(selectedLeadId.toString());
        await updateLeadsAndResetSelection();
      } catch (error) {
        console.error('Erro ao ativar lead:', error);
      } finally {
        setShowActivateModal(false);
      }
    }
  };

  const updateLeadsAndResetSelection = async () => {
    await fetchLeads();
    table.resetRowSelection();
  };

  const selectedLeads = table.getSelectedRowModel().rows;

  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const isAdminOrSuperAdmin = userData.accessType === 'admin' || userData.accessType === 'SuperAdmin';

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="mb-6">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '800px' }}>
            <Spinner
              animation="border"
              variant="primary"
              role="status"
              style={{ width: '50px', height: '50px' }}
            >
              <span className="visually-hidden">Carregando...</span>
            </Spinner>
          </div>
        ) : (
          <AdvanceTableProvider {...table}>
            <h2 className="mb-4">Leads</h2>
            <Row className="g-3 justify-content-between mb-4">
              <Col xs="auto" className="d-flex align-items-center">
                <Dropdown className="me-2">
                  <Dropdown.Toggle variant="primary" id="createLeadDropdown">
                    Create Lead
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setOpenAddLeadModal(true)}>
                      Pessoa Física
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setOpenAddLeadPJModal(true)}>
                      Pessoa Jurídica
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  variant="warning"
                  onClick={handleEditLeadClick}
                  className="me-2"
                  disabled={selectedLeads.length !== 1}
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" />
                  Editar
                </Button>
                {isAdminOrSuperAdmin && (
                  <>
                    <Button
                      variant="danger"
                      onClick={handleDeleteLeadClick}
                      className="me-2"
                      disabled={selectedLeads.length !== 1}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                      Excluir
                    </Button>
                    <Button
                      variant="success"
                      onClick={handleActivateLeadClick}
                      className="me-2"
                      disabled={selectedLeads.length !== 1}
                    >
                      <FontAwesomeIcon icon={faCheck} className="me-2" />
                      Ativar
                    </Button>
                  </>
                )}
              </Col>
              <Col xs="auto">
                <div className="d-flex">
                  <SearchBox
                    placeholder="Search by name"
                    className="me-2"
                    onChange={handleSearchInputChange}
                  />
                  <DatePicker defaultValue="Mar 1, 2022" />
                  <Button
                    variant="phoenix-secondary"
                    className="px-3 ms-2"
                    onClick={() => setOpenFilterModal(true)}
                  >
                    <FontAwesomeIcon
                      icon={faFilter}
                      transform="down-3"
                      className="text-primary"
                    />
                  </Button>
                </div>
                <LeadsFilterModal
                  show={openFilterModal}
                  handleClose={() => setOpenFilterModal(false)}
                />
              </Col>
            </Row>
            <LeadsTable />
          </AdvanceTableProvider>
        )}
      </div>
      <AddLeadModal
        show={openAddLeadModal}
        handleClose={() => setOpenAddLeadModal(false)}
        onLeadAdded={fetchLeads}
      />
      <AddLeadPJModal
        show={openAddLeadPJModal}
        handleClose={() => setOpenAddLeadPJModal(false)}
        onLeadAdded={fetchLeads}
      />
      <DeleteConfirmationModal
        show={showDeleteConfirmationModal}
        handleClose={() => setShowDeleteConfirmationModal(false)}
        handleConfirm={handleConfirmDelete}
        title="Confirmar Exclusão"
        message={`Tem certeza de que deseja excluir o lead?`}
      />
      <ActivateConfirmationModal
        show={showActivateModal}
        handleClose={() => setShowActivateModal(false)}
        handleConfirm={handleConfirmActivate}
        title="Confirmar Ativação"
        message="Tem certeza de que deseja ativar este lead?"
      />
    </div>
  );
};

export default Leads;
