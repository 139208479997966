import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Note } from 'data/crm/dealDetailsData';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { dealService } from 'services/api';

const DealDetailsNotes = ({ notes, dealId }: { notes: Note[], dealId: string }) => {
  const [newNoteText, setNewNoteText] = useState('');

  const handleSaveNote = async () => {
    if (newNoteText.trim()) {
      try {
        await dealService.createDealNote(dealId, newNoteText);
        setNewNoteText(''); // Limpa o campo após salvar
        // Aqui você pode adicionar lógica para atualizar a lista de notas, se necessário
      } catch (error) {
        console.error('Erro ao salvar a nota:', error);
      }
    }
  };

  return (
    <>
      <h2 className="mb-4">Notes</h2>
      <div className="d-flex mb-3">
        <Form.Control
          as="textarea"
          rows={4}
          value={newNoteText}
          onChange={(e) => setNewNoteText(e.target.value)}
          className="me-2"
        />
        <Button variant="primary" onClick={handleSaveNote}>
          Salvar
        </Button>
      </div>
      {notes.map((note, index) => (
        <div
          key={note.noteId}
          className={classNames('border-2 border-dashed border-translucent', {
            'mb-4 pb-4 border-bottom': index !== notes.length - 1
          })}
        >
          <p className="mb-1 text-body-highlight">{note.text}</p>
          <div className="d-sm-flex">
            <div className="fs-9 text-body-tertiary text-opacity-85">
              <FontAwesomeIcon icon={faClock} className="me-2" />
              <span className="fw-semibold me-1">{new Date(note.createdAt).toLocaleString()}</span>
            </div>
            <p className="fs-9 mb-0 text-body-tertiary text-opacity-85">
              by
              <Link to="#!" className="ms-1 fw-semibold">
                {note.creator.fullName}
              </Link>
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default DealDetailsNotes;
