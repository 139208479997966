import { faAngleRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import { FormEvent, useState, useEffect } from 'react';
import { FloatingLabel, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getRandomNumber } from 'helpers/utils';
import { useCalendarContext } from 'providers/CalendarProvider';
import { ADD_NEW_EVENT, SET_CALENDAR_STATE } from 'reducers/CalendarReducer';
import { CalendarEvent } from 'data/calendarEvents';
import usePhoenixForm from 'hooks/usePhoenixForm';
import ReactSelect from 'components/base/ReactSelect';
import { calendarService, EventData } from 'services/api';
import { MultiValue, ActionMeta } from 'react-select';
import { dealFormService } from 'services/api';
import logger from 'utils/logger';

interface OptionType {
  value: string;
  label: string;
}

const CalendarAddNewEventModal = () => {
  const {
    openNewEventModal,
    selectedStartDate,
    selectedEndDate,
    calendarDispatch
  } = useCalendarContext();

  const { formData, setFormData, onChange } = usePhoenixForm<CalendarEvent>({
    id: String(getRandomNumber(2000, 3000)),
    start: selectedStartDate,
    end: selectedEndDate
  });

  const [calendarOptions, setCalendarOptions] = useState([]);
  const [isCalendarFieldFocused, setIsCalendarFieldFocused] = useState(false);

  const [userOptions, setUserOptions] = useState<{ value: string; label: string }[]>([]);
  const [isParticipantsFieldFocused, setIsParticipantsFieldFocused] = useState(false);

  useEffect(() => {
    if (isCalendarFieldFocused && calendarOptions.length === 0) {
      const fetchCalendars = async () => {
        try {
          const calendars = await calendarService.listCalendars('1990-01-01', '1990-12-31');
          setCalendarOptions(calendars);
        } catch (error) {
          console.error('Erro ao buscar calendários:', error);
        }
      };

      fetchCalendars();
    }
  }, [isCalendarFieldFocused, calendarOptions.length]);

  useEffect(() => {
    if (isParticipantsFieldFocused && userOptions.length === 0) {
      const fetchUsers = async () => {
        try {
          const users = await dealFormService.getUsers();
          const formattedUsers = users.map(user => ({
            value: user.userId,
            label: user.fullName,
            type: 'User'
          }));
          setUserOptions(formattedUsers);
        } catch (error) {
          console.error('Erro ao buscar usuários:', error);
        }
      };

      fetchUsers();
    }
  }, [isParticipantsFieldFocused, userOptions.length]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    
    const formatDate = (dateString: string) => {
      return dateString 
        ? new Date(dateString.replace(/(\d{2})\/(\d{2})\/(\d{4}),\s*(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:00')).toISOString() 
        : '';
    };

    const eventData: EventData = {
      calendarId: formData.calendarId || '',
      type: 'Event',
      title: formData.title || '',
      startAt: formatDate(formData.start as string),
      endAt: formatDate(formData.end as string),
      description: formData.description || '',
      allDay: formData.allDay || false,
      participants: formData.participants?.map(participant => ({
        type: 'User',
        participantId: participant.participantId
      })) || []
    };

    logger.log('eventData', eventData);

    try {
      await calendarService.createEvent(eventData);
      calendarDispatch({
        type: ADD_NEW_EVENT,
        payload: formData
      });
      handleClose();
    } catch (error) {
      console.error('Erro ao criar evento:', error);
    }
  };

  const handleClose = () => {
    calendarDispatch({
      type: SET_CALENDAR_STATE,
      payload: {
        openNewEventModal: false
      }
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      start: selectedStartDate,
      end: selectedEndDate
    });
  }, [selectedEndDate, selectedStartDate]);

  return ( //TODO: Adicionar validação de data de início e fim maior que a data atual
    <Modal
      show={openNewEventModal}
      contentClassName="border-translucent"
      onHide={handleClose}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header className="px-card border-0">
          <div className="w-100 d-flex justify-content-between align-items-start">
            <div>
              <h5 className="mb-0 lh-sm text-body-highlight">Add new</h5>
              {/* <div className="mt-2">
                <Form.Check
                  type="radio"
                  id="event"
                  className="form-check-inline"
                >
                  <Form.Check.Input
                    type="radio"
                    name="pricingRadio"
                    defaultChecked
                  />
                  <Form.Check.Label htmlFor="event">Event</Form.Check.Label>
                </Form.Check>
                <Form.Check
                  type="radio"
                  id="task"
                  className="form-check-inline"
                >
                  <Form.Check.Input type="radio" name="pricingRadio" />
                  <Form.Check.Label htmlFor="task">Task</Form.Check.Label>
                </Form.Check>
              </div> */}
            </div>
            <Button className="p-1 fs-10 text-body" onClick={handleClose}>
              DISCARD
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-card py-0">
          <FloatingLabel controlId="event-title" label="Title" className="mb-3">
            <input
              className="form-control"
              onChange={onChange}
              name="title"
              type="text"
              placeholder="Title"
            />
          </FloatingLabel>
          <FloatingLabel controlId="eventLabel" label="Calendar" className="mb-5">
            <Form.Select
              name="calendarId"
              onFocus={() => setIsCalendarFieldFocused(true)}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setFormData(prev => ({
                  ...prev,
                  calendarId: selectedValue
                }));
              }}
              defaultValue=""
            >
              <option value="" disabled>
                Select calendar...
              </option>
              {calendarOptions.map((calendar: { calendarId: string; name: string }) => (
                <option key={calendar.calendarId} value={calendar.calendarId}>
                  {calendar.name}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <DatePicker
            options={{
              enableTime: true,
              dateFormat: 'd/m/Y H:i',
              time_24hr: true,
              onChange: (selectedDates: Date[]) => {
                if (selectedDates[0]) {
                  const formattedDate = selectedDates[0].toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                  });
                  setFormData(prev => ({
                    ...prev,
                    start: formattedDate
                  }));
                }
              }
            }}
            placeholder="Select date & time"
            render={(_, ref) => {
              return (
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Starts at"
                    ref={ref}
                    id="startDatepicker"
                  />
                  <label htmlFor="startDatepicker" className="ps-6">
                    Starts at
                  </label>
                </Form.Floating>
              );
            }}
          />
          <DatePicker
            options={{
              enableTime: true,
              dateFormat: 'd/m/Y H:i',
              time_24hr: true,
              onChange: (selectedDates: Date[]) => {
                if (selectedDates[0]) {
                  const formattedDate = selectedDates[0].toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                  });
                  setFormData(prev => ({
                    ...prev,
                    end: formattedDate
                  }));
                }
              }
            }}
            placeholder="Select date & time"
            render={(_, ref) => {
              return (
                <Form.Floating className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Ends at"
                    ref={ref}
                    id="endDatepicker"
                  />
                  <label htmlFor="endDatepicker" className="ps-6">
                    Ends at
                  </label>
                </Form.Floating>
              );
            }}
          />
          <Form.Check>
            <Form.Check.Input
              onChange={onChange}
              name="allDay"
              type="checkbox"
              id="all-day"
              checked={formData.allDay === true}
            />
            <Form.Check.Label htmlFor="all-day">All day event</Form.Check.Label>
          </Form.Check>
          <div className="py-5">
            <FloatingLabel controlId="description" label="Description">
              <textarea
                className="form-control"
                onChange={onChange}
                placeholder="Description"
                name="description"
                style={{ height: '128px' }}
              />
            </FloatingLabel>
          </div>
          <div className="mb-3">
            <Form.Label>Participantes</Form.Label>
            <ReactSelect
              options={userOptions}
              isMulti
              placeholder="Selecione os participantes..."
              onFocus={() => setIsParticipantsFieldFocused(true)}
              onChange={(newValue, actionMeta) => {
                const selectedOptions = newValue as MultiValue<OptionType>;
                setFormData(prev => ({
                  ...prev,
                  participants: selectedOptions.map(option => ({
                    type: 'User',
                    participantId: option.value
                  }))
                }));
              }}
            />
          </div>
          {/* <FloatingLabel
            controlId="repetition"
            label="Repetition"
            className="mb-3"
          >
            <Form.Select onChange={onChange} name="repetition">
              <option>Select</option>
              <option value="noRepeat">No Repeat</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="DailyExceptHolidays">
                Daily (except holidays)
              </option>
              <option value="custom">Custom</option>
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel controlId="reminder" label="Reminder" className="mb-3">
            <Form.Select onChange={onChange} name="reminder">
              <option>Select</option>
              <option value="1">30 minutes earlier</option>
              <option value="2">8 am on the day</option>
              <option value="3">8 am on the day before</option>
              <option value="4">2 days earlier</option>
              <option value="5">a week earlier</option>
            </Form.Select>
          </FloatingLabel>
          <Button
            variant="link"
            className="p-0 mb-3"
            startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
          >
            Add Reminder
          </Button> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items-center border-0">
          {/* <Link
            to="/apps/events/create-an-event"
            className="me-3 fs-9 text-body"
          >
            More options
            <FontAwesomeIcon icon={faAngleRight} className="ms-1 fs-10" />
          </Link> */}
          <Button variant="primary" type="submit" className="px-4">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CalendarAddNewEventModal;
