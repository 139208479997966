import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, Form, ProgressBar, Row } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import Avatar from 'components/base/Avatar';
import avatar from 'assets/img/team/72x72/58.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import Badge from 'components/base/Badge';
import { faCaretDown, faHeadphonesSimple } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from 'providers/AppProvider';
import type { DealDetails as IDealDetails, User } from 'services/api';
import { formatCurrency } from 'helpers/utils';
import { dealFormService } from 'services/api';
import { UilHeadphones } from '@iconscout/react-unicons';

interface DealProfileCardProps {
  className?: string;
  deal: IDealDetails;
}

const DealProfileCard = ({ className, deal }: DealProfileCardProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState(deal.responsibleUserId);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const {
    config: { isRTL }
  } = useAppContext();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dealFormService.getUsers();
        setUsers(response);
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleUserChange = async () => {
    if (selectedUserId === deal.responsibleUserId) return;
    
    setIsLoading(true);
    try {
      // Implemente aqui a chamada para atualizar o responsável
      // await dealService.updateResponsible(deal.dealId, selectedUserId);
      // Adicione feedback de sucesso
    } catch (error) {
      console.error('Erro ao atualizar responsável:', error);
      // Adicione feedback de erro
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Card className={className}>
      <Card.Body>
        <Row className="align-items-center g-3">
          <Col sm="auto" className="flex-1">
            <h3 className="fw-bolder mb-2 line-clamp-1">
              {deal.title}
            </h3>
            <div className="d-flex align-items-center mb-4">
              <h5 className="mb-0 me-4">
                {formatCurrency(Number(deal.totalValue))}
              </h5>
              <h5 className="fw-semibold">
                <FeatherIcon
                  icon="grid"
                  className="d-inline-block lh-sm me-1"
                  width={16}
                  height={16}
                />
                <span className="d-inline-block lh-sm">{deal.industry.name}</span>
              </h5>
            </div>
            <div className="d-md-flex d-xl-block align-items-center justify-content-between mb-5">
              <div className="d-flex align-items-center mb-3 mb-md-0 mb-xl-3">
                <Avatar size="xl" src={avatar} className="me-3" />
                <div>
                  <h5>{deal.responsibleUser.fullName}</h5>
                  <Dropdown align={isRTL ? 'end' : 'start'} show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
                    <Dropdown.Toggle
                      variant="link"
                      className="text-body-secondary text-decoration-none dropdown-caret-none p-0 fs-8 fw-normal"
                      onClick={() => setShowDropdown(!showDropdown)}
                    >
                      <UilHeadphones className="me-2" size={16} />
                      {deal.responsibleUser.fullName}
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        className="text-body-secondary fs-9 ms-2"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="shadow-sm"
                      style={{ minWidth: '20rem' }}
                    >
                      <Card className="position-relative border-0">
                        <Card.Body className="p-0">
                          <div className="mx-3">
                            <h4 className="mb-3 fw-bold">Alterar responsável</h4>
                            <h5 className="mb-3">Responsável pelo Deal</h5>
                            <Form.Select 
                              className="mb-3"
                              value={selectedUserId}
                              onChange={(e) => setSelectedUserId(e.target.value)}
                            >
                              {users.map(user => (
                                <option 
                                  key={user.userId} 
                                  value={user.userId}
                                >
                                  {user.fullName}
                                </option>
                              ))}
                            </Form.Select>
                            <div className="text-end">
                              <Button 
                                variant="link" 
                                className="text-danger"
                                onClick={() => {
                                  setSelectedUserId(deal.responsibleUserId);
                                  setShowDropdown(false);
                                }}
                              >
                                Cancelar
                              </Button>
                              <Button
                                size="sm"
                                variant="primary"
                                className="px-5"
                                onClick={handleUserChange}
                                disabled={isLoading || selectedUserId === deal.responsibleUserId}
                              >
                                {isLoading ? 'Salvando...' : 'Salvar'}
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div>
                <Badge 
                  variant="phoenix" 
                  bg={deal.priority === 'High' ? 'danger' : deal.priority === 'Medium' ? 'warning' : 'success'} 
                  className="me-2"
                >
                  {deal.priority}
                </Badge>
                <Badge variant="phoenix" bg="secondary">
                  {deal.stage}
                </Badge>
              </div>
            </div>
            <ProgressBar 
              variant="primary-lighter" 
              now={deal.probability} 
              className="mb-2" 
            />
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0">{deal.stage}</p>
              <div>
                <FeatherIcon
                  icon="clock"
                  className="lh-sm me-1"
                  width={16}
                  height={16}
                />
                <span className="d-inline-block lh-sm">
                  {formatDate(deal.deliveryDate)}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DealProfileCard;
