import DatePicker from 'components/base/DatePicker';
import { Col, FloatingLabel, Form } from 'react-bootstrap';

const EventsSchedule = () => {
  return (
    <>
      <h4 className="mt-7">Schedule</h4>
      <Col sm={6}>
      <DatePicker
          options={{
            enableTime: true,
            dateFormat: 'd/m/Y H:i',
            time_24hr: true,
            onChange: (selectedDates: Date[]) => {
              if (selectedDates[0]) {
                const formattedDate = selectedDates[0].toLocaleString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                });
              }
            }
          }}
          placeholder="Select Start Date & Time"
        />
      </Col>
      <Col sm={6}>
        <DatePicker
            options={{
              enableTime: true,
              dateFormat: 'd/m/Y H:i',
              time_24hr: true,
              onChange: (selectedDates: Date[]) => {
                if (selectedDates[0]) {
                  const formattedDate = selectedDates[0].toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                  });
                }
              }
            }}
            placeholder="Select End Date & Time"
          />
      </Col>
    </>
  );
};

export default EventsSchedule;
