import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Modal, Form, Toast, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { dealFormService } from 'services/api';

interface AddUserModalProps {
  show: boolean;
  handleClose: () => void;
  onUserAdded: () => void;
  fetchUsers: () => void;
}

const AddUserModal = ({ show, handleClose, onUserAdded, fetchUsers }: AddUserModalProps) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    contact: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    if (!show) {
      setFormData({
        fullName: '',
        email: '',
        password: '',
        contact: ''
      });
    }
  }, [show]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    // Validação dos campos obrigatórios
    if (!formData.fullName || !formData.email || !formData.password) {
      setToastMessage('Email, senha e nome completo são obrigatórios.');
      setShowToast(true);
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await dealFormService.addUser({
        fullName: formData.fullName,
        email: formData.email,
        password: formData.password,
        contact: formData.contact
      }); // Chama a API para adicionar o usuário
      setToastMessage('Usuário adicionado com sucesso!');
      setShowToast(true);
      onUserAdded(); // Chama a função para atualizar a lista de usuários
      fetchUsers(); // Chama a função para buscar usuários novamente
      handleClose(); // Fecha o modal após a adição
    } catch (error) {
      console.error('Erro ao adicionar usuário:', error);
      setToastMessage('Erro ao adicionar usuário. Tente novamente.');
      setShowToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={show}
        centered
        backdrop="static"
        onHide={handleClose}
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">Adicionar Usuário</h3>
          <Button
            variant="phoenix-secondary"
            className="ms-auto"
            onClick={handleClose}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Form className="d-flex flex-column gap-4">
            <Form.Group>
              <Form.Label className="form-label-header mb-2">Nome Completo</Form.Label>
              <Form.Control
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                type="text"
                placeholder="Digite o nome completo"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="form-label-header mb-2">Email</Form.Label>
              <Form.Control
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                type="email"
                placeholder="Digite o email"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="form-label-header mb-2">Senha</Form.Label>
              <Form.Control
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                type="password"
                placeholder="Digite a senha"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="form-label-header mb-2">Contato</Form.Label>
              <Form.Control
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                type="text"
                placeholder="Digite o número de contato"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button variant="link" className="text-danger px-3 my-0" onClick={handleClose} disabled={isSubmitting}>
            Cancelar
          </Button>
          {isSubmitting ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Adicionando...
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Adicionar Usuário
            </Button>
          )}
        </Modal.Footer>
        <Toast 
          show={showToast} 
          onClose={() => setShowToast(false)}
          autohide
          delay={3000}
          style={{ position: 'fixed', bottom: 20, right: 20 }}
        >
          <Toast.Header>
            <strong className="me-auto">Notificação</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </Modal>
    </>
  );
};

export default AddUserModal; 