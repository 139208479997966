import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Modal, Row, Spinner, Toast } from 'react-bootstrap';
import { useState } from 'react';
import { boardService } from 'services/api';

interface AddBoardModalProps {
  show: boolean;
  handleClose: () => void;
  onBoardCreated: () => void;
}

interface FormData {
  title: string;
  description: string;
  visibility: 'public' | 'private' | 'desktop' | 'organization';
  coverImage: string;
}

const AddBoardModal = ({ show, handleClose, onBoardCreated }: AddBoardModalProps) => {
  const [formData, setFormData] = useState<FormData>({
    title: '',
    description: '',
    visibility: 'private',
    coverImage: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await boardService.createBoard(formData);
      handleClose();
      setToastMessage('Board criado com sucesso!');
      setShowToast(true);
      onBoardCreated();
    } catch (error) {
      console.error('Erro ao criar board:', error);
      setToastMessage('Erro ao criar board');
      setShowToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        centered
        backdrop="static"
        onHide={handleClose}
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">Informações do Board</h3>
          <Button
            variant="phoenix-secondary"
            className="ms-auto"
            onClick={handleClose}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Row className="g-4">
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-header mb-2">Título</Form.Label>
                <Form.Control
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Digite o título do board"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-header mb-2">Descrição</Form.Label>
                <Form.Control
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  as="textarea"
                  rows={3}
                  placeholder="Digite a descrição do board"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-header mb-2">Visibilidade</Form.Label>
                <Form.Select
                  name="visibility"
                  value={formData.visibility}
                  onChange={handleInputChange}
                >
                  <option value="public">Público</option>
                  <option value="private">Privado</option>
                  <option value="desktop">Desktop</option>
                  <option value="organization">Organização</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-label-header mb-2">Imagem de Capa</Form.Label>
                <Form.Control
                  name="coverImage"
                  value={formData.coverImage}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="URL da imagem de capa"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-danger px-3 my-0"
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          {isSubmitting ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Criando...
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Criar Board
            </Button>
          )}
        </Modal.Footer>
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          autohide
          delay={3000}
          style={{ position: 'fixed', bottom: 20, right: 20 }}
        >
          <Toast.Header>
            <strong className="me-auto">Notificação</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </Modal>
    </>
  );
};

export default AddBoardModal; 