const setLogLevel: Record<string, number> = {
  "trace": 6,
  "debug": 5,
  "log": 4,
  "info": 4,
  "warn": 3,
  "error": 2,
  "fatal": 1
};

const getLogLevelFromLocalStorage = (): number => {
  if (window.location.hostname === 'localhost') {
    return 6;
  }
  const logLevel = localStorage.getItem('debugLevel');
  return logLevel ? parseInt(logLevel, 10) || 0 : 0;
};

const logger = {
  trace: (...args: any[]) => { 
    if (logger.level >= 6) 
      console.log(...args); 
  },
  debug: (...args: any[]) => { 
    if (logger.level >= 5) 
      console.log(...args); 
  },
  log: (...args: any[]) => { 
    if (logger.level >= 4) 
      console.log(...args); 
  },
  info: (...args: any[]) => { 
    if (logger.level >= 4) 
      console.log(...args); 
  },
  warn: (...args: any[]) => { 
    if (logger.level >= 3) 
      console.error(...args); 
  },
  error: (...args: any[]) => { 
    if (logger.level >= 2) 
      console.error(...args); 
  },
  fatal: (...args: any[]) => { 
    if (logger.level >= 1) 
      console.error(...args); 
  },
  parseLogLevel: (logLevel: string) => { 
    return setLogLevel[logLevel]; 
  },
  level: getLogLevelFromLocalStorage()
};

export default logger;