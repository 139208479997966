import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Link } from 'react-router-dom';

// Desativar a regra de validação de props do ESLint para este componente
/* eslint-disable react/prop-types */

interface DealAddBoardCardProps {
  onAddBoardClick: () => void;
}

const DealAddBoardCard: React.FC<DealAddBoardCardProps> = ({ onAddBoardClick }) => {
  return (
    <Button
      variant="phoenix-primary"
      className="d-flex flex-center h-100 w-100 fs-7 fw-semibold"
      onClick={onAddBoardClick}
    >
      <FontAwesomeIcon icon={faPlusCircle} className="fs-8 me-2" />
      Adicionar Board
    </Button>
  );
};

export default DealAddBoardCard;
