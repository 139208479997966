export interface FileUploadPayload {
  fileName: string;
  fileSize: number;
  fileType: string;
  lastModified: number;
  blob: string;
}

export const createFileUploadPayload = (file: File): Promise<FileUploadPayload> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = () => {
      try {
        const base64Data = reader.result?.toString().split(',')[1];
        
        if (!base64Data) {
          reject(new Error('Failed to convert file to base64'));
          return;
        }

        resolve({
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          lastModified: file.lastModified,
          blob: base64Data
        });
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };

    reader.readAsDataURL(file);
  });
}; 