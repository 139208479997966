import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { DealColumn } from 'data/crm/deals';
import { getRandomNumber } from 'helpers/utils';
import usePhoenixForm from 'hooks/usePhoenixForm';
import { useDealsContext } from 'providers/CrmDealsProvider';
import { FormEvent, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

interface DealsAddStageModalProps {
  show: boolean;
  handleClose: () => void;
  initialData?: DealColumn | null;
  isEditing?: boolean;
  onSubmit?: (formData: Partial<DealColumn>) => Promise<void>;
}

const initFormData = {
  id: '',
  title: '',
  revenue: 0,
  deals: [],
  position: 0
};

const DealsAddStageModal = ({
  show,
  handleClose,
  initialData,
  isEditing = false,
  onSubmit
}: DealsAddStageModalProps) => {
  const { onChange, formData, setFormData } = usePhoenixForm<DealColumn>();
  const { handleAddStage } = useDealsContext();

  useEffect(() => {
    if (show) {
      if (isEditing && initialData) {
        console.log('Carregando dados iniciais:', initialData);
        setFormData({
          id: initialData.id,
          title: initialData.title,
          revenue: initialData.revenue,
          deals: initialData.deals || [],
          position: initialData.position || 0
        });
      } else {
        setFormData(initFormData);
      }
    }
  }, [isEditing, initialData, show, setFormData]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (isEditing && onSubmit) {
        console.log('Enviando dados de edição:', formData);
        await onSubmit(formData);
      } else {
        await handleAddStage(formData);
      }
      handleClose();
    } catch (error) {
      console.error('Erro ao salvar stage:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered contentClassName="border">
      <Form onSubmit={handleSubmit}>
        <Modal.Header className="border-0 p-5">
          <h5 className="modal-title text-body-highlight fs-6 lh-sm">
            {isEditing ? 'Edit Stage' : 'Create New Stage'}
          </h5>
          <Button
            variant="phoenix-secondary"
            className="ms-auto"
            onClick={handleClose}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-5 py-0 mb-2">
          <Form.Group className="mb-4">
            <Form.Label className="form-label-header mb-2">
              Column Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter column name"
              value={formData.title}
              name="title"
              onChange={onChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="form-label-header mb-2">
              Expected Revenue
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="$ Enter amount"
              className="flex-1 input-spin-none"
              value={formData.revenue}
              name="revenue"
              required
              onChange={onChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center border-0 p-5">
          <Button
            variant="link"
            className="px-4 m-0 text-danger"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" className="px-4 m-0">
            {isEditing ? 'Save Changes' : 'Create New Stage'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DealsAddStageModal;
