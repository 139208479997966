import {
  faFileExport,
  faFilter,
  faPlus,
  faRotateRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import DealColumn from 'components/modules/crm/deals/DealColumn';
import AddDealModal from 'components/modules/crm/deals/AddDealModal';
import FilterDealsModal from 'components/modals/FilterDealsModal';
import PhoenixDroppable from 'components/base/PhoenixDroppable';
import DealsAddStageModal from 'components/modals/DealsAddStageModal';
import DealsProvider, { useDealsContext } from 'providers/CrmDealsProvider';
import { useParams } from 'react-router-dom';

const index = () => {
  const { boardId } = useParams();
  
  return (
    <DealsProvider boardId={boardId}>
      <Deals />
    </DealsProvider>
  );
};

const Deals = () => {
  const { setContentClass } = useMainLayoutContext();
  const {
    dealColumns,
    openAddDealModal,
    setOpenAddDealModal,
    openFilterDealModal,
    setOpenFilterDealModal,
    openAddStageModal,
    setOpenAddStageModal,
    handleDragEnd,
    openEditStageModal,
    setOpenEditStageModal,
    selectedColumn,
    setSelectedColumn,
    handleEditStage,
    setSelectedColumnId,
    refreshBoard,
  } = useDealsContext();

  useEffect(() => {
    setContentClass('vh-100');

    return () => {
      setContentClass('');
    };
  }, []);

  return (
    <div className="d-flex flex-column h-auto">
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <div className="mb-6">
        <div className="d-flex align-items-center mb-5">
          <h2 className="mb-0">Deals</h2>
          <Button
            variant="link"
            className="ms-2 p-0"
            onClick={refreshBoard}
          >
            <FontAwesomeIcon icon={faRotateRight} className="fs-7" />
          </Button>
        </div>
        <Row className="g-3 justify-content-between">
          <Col xs="auto">
            <Button
              variant="primary"
              className="me-4"
              startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
              onClick={() => setOpenAddDealModal(true)}
            >
              Add Deal
            </Button>
            <Button
              variant="link"
              className="text-body px-0"
              startIcon={
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
              }
            >
              Export
            </Button>
          </Col>
          <Col xs="auto">
            <div className="d-flex">
              <SearchBox placeholder="Search by name" className="me-2" />
              <Form.Select className="w-auto">
                <option value="deals">Deals</option>
              </Form.Select>
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
                onClick={() => setOpenFilterDealModal(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  transform="down-3"
                  className="text-primary"
                />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <PhoenixDroppable 
          droppableId="board" 
          direction="horizontal" 
          type="COLUMN"
        >
          {(provided) => (
            <div 
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="mx-n4 px-4 mx-lg-n6 px-lg-6 flex-1 d-flex gap-4 scrollbar h-100 overflow-x-auto"
            >
              {dealColumns.map((col, index) => (
                <Draggable 
                  key={col.id} 
                  draggableId={col.id} 
                  index={index}
                >
                  {(providedDrag) => (
                    <div
                      ref={providedDrag.innerRef}
                      {...providedDrag.draggableProps}
                      {...providedDrag.dragHandleProps}
                    >
                      <PhoenixDroppable droppableId={col.id} type="DEAL">
                        {providedDrop => (
                          <div ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
                            <DealColumn
                              column={col}
                              handleOpenAddModal={() => setOpenAddDealModal(true)}
                            />
                            {providedDrop.placeholder}
                          </div>
                        )}
                      </PhoenixDroppable>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <div className="deals-column flex-center flex-shrink-0">
                <h3 className="mb-4">Add new stage</h3>
                <Button
                  variant="primary"
                  size="sm"
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => setOpenAddStageModal(true)}
                >
                  New Stage
                </Button>
              </div>
            </div>
          )}
        </PhoenixDroppable>
      </DragDropContext>

      <AddDealModal
        show={openAddDealModal}
        handleClose={() => {
          setOpenAddDealModal(false);
          setSelectedColumnId('');
        }}
      />
      <FilterDealsModal
        show={openFilterDealModal}
        handleClose={() => setOpenFilterDealModal(false)}
      />
      <DealsAddStageModal
        show={openAddStageModal}
        handleClose={() => setOpenAddStageModal(false)}
      />
      
      <DealsAddStageModal
        show={openEditStageModal}
        handleClose={() => {
          setOpenEditStageModal(false);
          setSelectedColumn(null);
        }}
        initialData={selectedColumn}
        isEditing={true}
        onSubmit={async (formData) => {
          if (!selectedColumn?.id) {
            console.error('ID da coluna não encontrado');
            return;
          }
          try {
            await handleEditStage(selectedColumn.id, formData);
            setOpenEditStageModal(false);
            setSelectedColumn(null);
          } catch (error) {
            console.error('Erro ao editar coluna:', error);
            // Aqui você pode adicionar um toast ou notificação de erro
          }
        }}
      />
    </div>
  );
};

export default index;
