import { DealColumn, Deal } from 'data/crm/deals';
import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
  useContext,
  useCallback,
  useEffect
} from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { boardService, dealService } from 'services/api';

interface DealsContextInterface {
  dealColumns: DealColumn[];
  setDealColumns: Dispatch<SetStateAction<DealColumn[]>>;
  openAddDealModal: boolean;
  setOpenAddDealModal: Dispatch<SetStateAction<boolean>>;
  openFilterDealModal: boolean;
  setOpenFilterDealModal: Dispatch<SetStateAction<boolean>>;
  openAddStageModal: boolean;
  setOpenAddStageModal: Dispatch<SetStateAction<boolean>>;
  handleDragEnd: (result: DropResult) => void;
  handleAddStage: (formData: DealColumn) => Promise<void>;
  selectedColumnId: string;
  setSelectedColumnId: Dispatch<SetStateAction<string>>;
  boardId: string;
  refreshBoard: () => Promise<void>;
  openEditStageModal: boolean;
  setOpenEditStageModal: Dispatch<SetStateAction<boolean>>;
  selectedColumn: DealColumn | null;
  setSelectedColumn: Dispatch<SetStateAction<DealColumn | null>>;
  handleEditStage: (columnId: string, formData: Partial<DealColumn>) => Promise<void>;
  handleDeleteStage: (columnId: string) => Promise<void>;
}

export const DealsContext = createContext({} as DealsContextInterface);

interface DealsProviderProps {
  children: React.ReactNode;
  boardId?: string;
}

export const DealsProvider = ({ children, boardId = '' }: DealsProviderProps) => {
  const [dealColumns, setDealColumns] = useState<DealColumn[]>([]);
  const [openAddDealModal, setOpenAddDealModal] = useState(false);
  const [openFilterDealModal, setOpenFilterDealModal] = useState(false);
  const [openAddStageModal, setOpenAddStageModal] = useState(false);
  const [selectedColumnId, setSelectedColumnId] = useState<string>('');
  const [openEditStageModal, setOpenEditStageModal] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState<DealColumn | null>(null);

  useEffect(() => {
    console.log('selectedColumnId updated:', selectedColumnId);
  }, [selectedColumnId]);

  const fetchBoard = useCallback(async () => {
    if (!boardId) return;
    
    try {
      const boardData = await boardService.getBoard(boardId);
      const formattedColumns = boardData.columns
        .map((column: any) => ({
          id: column.columnId,
          title: column.title,
          position: column.position || 0,
          revenue: Number(column.totalValue),
          deals: column.deals
            .map((deal: any) => ({
              id: deal.dealId,
              position: deal.position || 0,
              title: deal.title,
              revenue: Number(deal.totalValue),
              category: deal.industry.name || '',
              date: new Date(deal.createdAt).toLocaleDateString(),
              time: new Date(deal.createdAt).toLocaleTimeString(),
              closingDate: deal.deliveryDate ? new Date(deal.deliveryDate).toLocaleDateString() : '',
              closingTime: deal.deliveryDate ? new Date(deal.deliveryDate).toLocaleTimeString() : '',
              company: deal.company?.name || 'Sem empresa',
              agent: deal.responsibleUser.fullName,
              status: {
                label: deal.stage,
                variant: getVariantByStage(deal.stage)
              },
              priority: {
                label: deal.priority,
                variant: getVariantByPriority(deal.priority)
              },
              probability: {
                value: String(deal.probability),
                variant: getVariantByProbability(deal.probability)
              },
              responsibleUser: {
                fullName: deal.responsibleUser.fullName,
                email: deal.responsibleUser.email
              }
            }))
            .sort((a: any, b: any) => a.position - b.position)
        }))
        .sort((a: any, b: any) => a.position - b.position);

      setDealColumns(formattedColumns);
    } catch (error) {
      console.error('Erro ao buscar board:', error);
    }
  }, [boardId]);

  useEffect(() => {
    fetchBoard();
  }, [fetchBoard]);

  const calculateNewIndex = (
    sourceIndex: number,
    destinationIndex: number,
    columns: DealColumn[]
  ): number => {
    const MIN_INDEX = 10000;
    const MAX_INDEX = 99990000;
    const STEP = 10000;

    // Ordena as colunas por posição para garantir o cálculo correto
    const sortedColumns = [...columns].sort((a, b) => a.position - b.position);

    // Se for a primeira posição
    if (destinationIndex === 0) {
      const firstPosition = sortedColumns[0]?.position || MIN_INDEX;
      return Math.floor(firstPosition / 2);
    }

    // Se for a última posição
    if (destinationIndex >= sortedColumns.length) {
      const lastPosition = sortedColumns[sortedColumns.length - 1]?.position || 0;
      return lastPosition + STEP;
    }

    // Posição entre duas colunas existentes
    const beforePosition = sortedColumns[destinationIndex - 1]?.position || 0;
    const afterPosition = sortedColumns[destinationIndex]?.position || MAX_INDEX;
    return Math.floor(beforePosition + (afterPosition - beforePosition) / 2);
  };

  const calculateNewDealIndex = (
    sourceIndex: number,
    destinationIndex: number,
    deals: Deal[]
  ): number => {
    const MIN_INDEX = 10000;
    const MAX_INDEX = 99990000;
    const STEP = 10000;

    // Ordena os deals por posição
    const sortedDeals = [...deals].sort((a, b) => a.position - b.position);

    // Se for a primeira posição
    if (destinationIndex === 0) {
      const firstPosition = sortedDeals[0]?.position || MAX_INDEX;
      return Math.floor(MIN_INDEX + (firstPosition - MIN_INDEX) / 2);
    }

    // Se for a última posição
    if (destinationIndex >= sortedDeals.length) {
      const lastPosition = sortedDeals[sortedDeals.length - 1]?.position || 0;
      return lastPosition + STEP;
    }

    // Posição entre dois deals existentes
    const beforePosition = sortedDeals[destinationIndex - 1]?.position || 0;
    const afterPosition = sortedDeals[destinationIndex]?.position || MAX_INDEX;
    return Math.floor(beforePosition + (afterPosition - beforePosition) / 2);
  };

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      const { source, destination, draggableId, type } = result;

      if (!destination) return;

      // Se for movimento de coluna
      if (type === 'COLUMN') {
        if (source.index === destination.index) return;

        try {
          // Ordena as colunas por posição
          const sortedColumns = [...dealColumns].sort((a, b) => a.position - b.position);
          
          // Encontra a coluna que está sendo movida
          const movedColumn = sortedColumns.find(col => col.id === draggableId);
          if (!movedColumn) return;

          // Remove a coluna da posição atual
          const columnsWithoutMoved = sortedColumns.filter(col => col.id !== draggableId);
          
          // Calcula o novo índice
          const newIndex = calculateNewIndex(
            source.index,
            destination.index,
            columnsWithoutMoved
          );

          // Cria o novo array de colunas com a coluna movida na nova posição
          const newColumns = [
            ...columnsWithoutMoved.slice(0, destination.index),
            { ...movedColumn, position: newIndex },
            ...columnsWithoutMoved.slice(destination.index)
          ].sort((a, b) => a.position - b.position);

          // Atualização otimista da UI
          setDealColumns(newColumns);

          // Atualiza no backend
          await boardService.moveColumn(draggableId, {
            newIndex: newIndex
          });
        } catch (error) {
          console.error('Erro ao mover coluna:', error);
          fetchBoard(); // Reverte em caso de erro
          return;
        }
        return;
      }

      // Se for movimento de deal
      if (source.droppableId === destination.droppableId &&
          source.index === destination.index) {
        return;
      }

      try {
        const sourceColumn = dealColumns.find(col => col.id === source.droppableId);
        const destColumn = dealColumns.find(col => col.id === destination.droppableId);

        if (!sourceColumn || !destColumn) return;

        // Cria cópias dos arrays para manipulação
        const newColumns = Array.from(dealColumns);
        const sourceDeals = Array.from(sourceColumn.deals);
        const destDeals = source.droppableId === destination.droppableId
          ? sourceDeals
          : Array.from(destColumn.deals);

        // Remove o deal da coluna de origem
        const [movedDeal] = sourceDeals.splice(source.index, 1);

        // Calcula o novo índice usando fractional indexing
        const newIndex = calculateNewDealIndex(
          source.index,
          destination.index,
          destDeals
        );

        // Insere o deal na coluna de destino
        destDeals.splice(destination.index, 0, { ...movedDeal, position: newIndex });

        // Atualiza as colunas com os novos arrays de deals
        const updatedColumns = newColumns.map(col => {
          if (col.id === source.droppableId) {
            return { ...col, deals: sourceDeals };
          }
          if (col.id === destination.droppableId) {
            return { ...col, deals: destDeals };
          }
          return col;
        });

        // Atualização otimista da UI
        setDealColumns(updatedColumns);

        // Atualiza no backend
        await dealService.moveDeal(draggableId, {
          sourceColumnId: source.droppableId,
          destinationColumnId: destination.droppableId,
          newIndex: newIndex
        });
      } catch (error) {
        console.error('Erro ao mover deal:', error);
        fetchBoard(); // Reverte em caso de erro
      }
    },
    [dealColumns, fetchBoard]
  );

  const handleAddStage = useCallback(async (formData: DealColumn) => {
    if (!boardId) return;
    
    try {
      await boardService.addColumn({
        title: formData.title,
        boardId: boardId,
        totalValue: formData.revenue
      });
      
      await fetchBoard();
    } catch (error) {
      console.error('Erro ao criar nova coluna:', error);
    }
  }, [boardId, fetchBoard]);

  const handleEditStage = useCallback(async (columnId: string, formData: Partial<DealColumn>) => {
    if (!boardId || !columnId) {
      console.error('BoardId ou ColumnId não fornecidos');
      return;
    }
    
    try {
      await boardService.updateColumn({
        columnId: columnId,
        boardId: boardId,
        title: formData.title || '',
        totalValue: formData.revenue || 0
      });
      
      await fetchBoard();
    } catch (error) {
      console.error('Erro ao atualizar coluna:', error);
      throw error; // Propaga o erro para tratamento no componente
    }
  }, [boardId, fetchBoard]);

  const handleDeleteStage = useCallback(async (columnId: string) => {
    if (!boardId || !columnId) {
      console.error('BoardId ou ColumnId não fornecidos');
      return;
    }
    
    try {
      await boardService.deleteColumn(columnId);
      await fetchBoard();
    } catch (error: any) {
      console.error('Erro ao deletar coluna:', error);
      if (error.response?.status === 400) {
        throw new Error(error.response.data.message);
      }
      throw new Error('Erro ao excluir a coluna');
    }
  }, [boardId, fetchBoard]);

  const value = {
    dealColumns,
    setDealColumns,
    openAddDealModal,
    setOpenAddDealModal,
    openFilterDealModal,
    setOpenFilterDealModal,
    openAddStageModal,
    setOpenAddStageModal,
    handleDragEnd,
    handleAddStage,
    selectedColumnId,
    setSelectedColumnId,
    boardId,
    refreshBoard: fetchBoard,
    openEditStageModal,
    setOpenEditStageModal,
    selectedColumn,
    setSelectedColumn,
    handleEditStage,
    handleDeleteStage
  };

  return (
    <DealsContext.Provider value={value}>
      {children}
    </DealsContext.Provider>
  );
};

export const useDealsContext = () => useContext(DealsContext);

export default DealsProvider;

// Funções auxiliares para mapear variantes
enum DealStatus {
  New = 'New',
  Pending = 'Pending',
  Completed = 'Completed',
  Canceled = 'Canceled'
}

enum Priority {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Urgent = 'Urgent'
}

const getVariantByStage = (stage: string): string => {
  const variants: Record<DealStatus, string> = {
    'New': 'info',
    'Pending': 'warning',
    'Completed': 'success',
    'Canceled': 'secondary'
  };
  return variants[stage as DealStatus] || 'info';
};

const getVariantByPriority = (priority: string): string => {
  const variants: Record<Priority, string> = {
    'High': 'warning',
    'Medium': 'success',
    'Low': 'info',
    'Urgent': 'danger'
  };
  return variants[priority as Priority] || 'info';
};

const getVariantByProbability = (probability: number): string => {
  if (probability >= 80) return 'success';
  if (probability >= 60) return 'warning';
  if (probability >= 40) return 'primary';
  return 'info';
};
