import { faEllipsisH, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { DealColumn as Column } from 'data/crm/deals';
import { currencyFormat } from 'helpers/utils';
import { Dropdown } from 'react-bootstrap';
import DealCard from 'components/cards/DealCard';
import { Draggable } from 'react-beautiful-dnd';
import { useDealsContext } from 'providers/CrmDealsProvider';
import DeleteConfirmationModal from 'components/modals/DeleteConfirmationModal';
import { useState } from 'react';
import { Toast } from 'react-bootstrap';

const DealColumn = ({
  column,
  handleOpenAddModal
}: {
  column: Column;
  handleOpenAddModal: () => void;
}) => {
  const { setSelectedColumn, setOpenEditStageModal, setSelectedColumnId, handleDeleteStage } = useDealsContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleAddDeal = () => {
    setSelectedColumnId(column.id);
    setSelectedColumn(column);
    handleOpenAddModal();
  };

  const handleEdit = () => {
    if (!column?.id) {
      console.error('ID da coluna não encontrado');
      return;
    }
    console.log('Editando coluna:', column); // Para debug
    setSelectedColumn(column);
    setOpenEditStageModal(true);
  };

  const handleDelete = async () => {
    if (!column?.id) {
      console.error('ID da coluna não encontrado');
      return;
    }
    
    setIsDeleting(true);
    try {
      await handleDeleteStage(column.id);
      setShowDeleteModal(false);
    } catch (error: any) {
      setToastMessage(error.message);
      setShowToast(true);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <div className="deals-column d-flex flex-column" style={{ height: 'calc(100vh - 250px)' }}>
        <div className="d-flex align-items-center justify-content-between position-sticky top-0 z-1 bg-body">
          <div>
            <h5 className="mb-2">{column.title}</h5>
            <p className="fs-9 text-body-tertiary mb-1">Forecast Revenue:</p>
            <h4 className="mb-3">
              {currencyFormat(column.revenue, {
                minimumFractionDigits: 2
              })}
            </h4>
          </div>
          <div className="d-flex gap-3">
            <Button className="p-0" onClick={handleAddDeal}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Dropdown align="end">
              <Dropdown.Toggle
                variant=""
                className="dropdown-caret-none p-0 fs-10"
              >
                <FontAwesomeIcon icon={faEllipsisH} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
                <Dropdown.Item 
                  className="text-danger" 
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="scrollbar flex-1 d-flex flex-column overflow-y-auto">
          {column.deals.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {provided => (
                <>
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DealCard deal={item} key={item.id} columnId={column.id} />
                  </div>
                </>
              )}
            </Draggable>
          ))}
        </div>
      </div>

      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleConfirm={handleDelete}
        title="Confirmar Exclusão"
        message="Tem certeza que deseja excluir esta coluna? Esta ação não pode ser desfeita."
        isLoading={isDeleting}
      />

      <Toast 
        show={showToast} 
        onClose={() => setShowToast(false)}
        autohide
        delay={3000}
        style={{ position: 'fixed', bottom: 20, right: 20 }}
      >
        <Toast.Header>
          <strong className="me-auto">Erro</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </>
  );
};

export default DealColumn;
