import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import type { DealDetails as IDealDetails, DealCategory, Industry, LeadSource, CampaignSource, DealType } from 'services/api';
import { dealFormService, dealService } from 'services/api';

interface DealsOtherInformationProps {
  deal: IDealDetails;
}

const DealsOtherInformation = ({ deal }: DealsOtherInformationProps) => {
  const [categories, setCategories] = useState<DealCategory[]>([]);
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [leadSources, setLeadSources] = useState<LeadSource[]>([]);
  const [campaignSources, setCampaignSources] = useState<CampaignSource[]>([]);
  const [dealTypes, setDealTypes] = useState<DealType[]>([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [industriesLoaded, setIndustriesLoaded] = useState(false);
  const [leadSourcesLoaded, setLeadSourcesLoaded] = useState(false);
  const [campaignSourcesLoaded, setCampaignSourcesLoaded] = useState(false);
  const [dealTypesLoaded, setDealTypesLoaded] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(deal.categoryId);
  const [selectedIndustryId, setSelectedIndustryId] = useState(deal.industryId);
  const [selectedLeadSourceId, setSelectedLeadSourceId] = useState(deal.leadSourceId);
  const [selectedCampaignSourceId, setSelectedCampaignSourceId] = useState(deal.campaignSourceId);
  const [selectedDealTypeId, setSelectedDealTypeId] = useState(deal.typeId);

  const handleCategoryClick = async () => {
    if (!categoriesLoaded) {
      const categoriesData = await dealFormService.getDealCategories();
      setCategories(categoriesData);
      setCategoriesLoaded(true);
    }
  };

  const handleIndustryClick = async () => {
    if (!industriesLoaded) {
      const industriesData = await dealFormService.getDealIndustries();
      setIndustries(industriesData);
      setIndustriesLoaded(true);
    }
  };

  const handleLeadSourceClick = async () => {
    if (!leadSourcesLoaded) {
      const leadSourcesData = await dealFormService.getLeadSources();
      setLeadSources(leadSourcesData);
      setLeadSourcesLoaded(true);
    }
  };

  const handleCampaignSourceClick = async () => {
    if (!campaignSourcesLoaded) {
      const campaignSourcesData = await dealFormService.getCampaignSources();
      setCampaignSources(campaignSourcesData);
      setCampaignSourcesLoaded(true);
    }
  };

  const handleDealTypeClick = async () => {
    if (!dealTypesLoaded) {
      const dealTypesData = await dealFormService.getDealTypes();
      setDealTypes(dealTypesData);
      setDealTypesLoaded(true);
    }
  };

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
    field: keyof IDealDetails,
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const newValue = event.target.value;
    const previousValue = event.target.value;

    setState(newValue);

    try {
      await dealService.updateDeal(deal.dealId, { [field]: newValue });
    } catch (error) {
      console.error(`Erro ao atualizar o campo ${field} do negócio:`, error);
      setState(previousValue);
    }
  };

  return (
    <Card>
      <Card.Body>
        <h4 className="mb-5">Others Information</h4>
        <Row className="g-3">
          <Col xs={12}>
            <div className="mb-4">
              <h5 className="mb-0 text-body-highlight mb-2">Priority</h5>
              <Form.Select>
                <option value="low">Low</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="urgent">Urgent</option>
              </Form.Select>
            </div>
            <div className="mb-4">
              <h5 className="mb-0 text-body-highlight mb-2">Stage</h5>
              <Form.Select>
                <option value="new">New</option>
                <option value="in-progress">In Progress</option>
                <option value="pending">Pending</option>
                <option value="canceled">Canceled</option>
                <option value="completed">Completed</option>
              </Form.Select>
            </div>
            <div className="mb-4">
              <div className="d-flex flex-wrap justify-content-between mb-2">
                <h5 className="mb-0 text-body-highlight me-2">Category</h5>
                <Link to="#!" className="fw-bold fs-9" onClick={handleCategoryClick}>
                  Add new category
                </Link>
              </div>
              <Form.Select
                value={selectedCategoryId}
                onChange={(e) => handleSelectChange(e, 'categoryId', setSelectedCategoryId)}
                onClick={handleCategoryClick}
              >
                {!categoriesLoaded && (
                  <option value={deal.categoryId}>{deal.category.name}</option>
                )}
                {categories.map(category => (
                  <option key={category.dealCategoryId} value={category.dealCategoryId}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="mb-4">
              <div className="d-flex flex-wrap justify-content-between mb-2">
                <h5 className="mb-0 text-body-highlight me-2">Industry</h5>
                <Link to="#!" className="fw-bold fs-9" onClick={handleIndustryClick}>
                  Add new industry
                </Link>
              </div>
              <Form.Select
                value={selectedIndustryId}
                onChange={(e) => handleSelectChange(e, 'industryId', setSelectedIndustryId)}
                onClick={handleIndustryClick}
              >
                {!industriesLoaded && (
                  <option value={deal.industryId}>{deal.industry.name}</option>
                )}
                {industries.map(industry => (
                  <option key={industry.dealIndustryId} value={industry.dealIndustryId}>
                    {industry.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="mb-4">
              <div className="d-flex flex-wrap justify-content-between mb-2">
                <h5 className="mb-0 text-body-highlight me-2">Lead Source</h5>
                <Link to="#!" className="fw-bold fs-9" onClick={handleLeadSourceClick}>
                  Add new
                </Link>
              </div>
              <Form.Select
                value={selectedLeadSourceId}
                onChange={(e) => handleSelectChange(e, 'leadSourceId', setSelectedLeadSourceId)}
                onClick={handleLeadSourceClick}
              >
                {!leadSourcesLoaded && (
                  <option value={deal.leadSourceId}>{deal.leadSource.name}</option>
                )}
                {leadSources.map(leadSource => (
                  <option key={leadSource.leadSourceId} value={leadSource.leadSourceId}>
                    {leadSource.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="mb-4">
              <div className="d-flex flex-wrap justify-content-between mb-2">
                <h5 className="mb-0 text-body-highlight me-2">Campaign Source</h5>
                <Link to="#!" className="fw-bold fs-9" onClick={handleCampaignSourceClick}>
                  Add new
                </Link>
              </div>
              <Form.Select
                value={selectedCampaignSourceId}
                onChange={(e) => handleSelectChange(e, 'campaignSourceId', setSelectedCampaignSourceId)}
                onClick={handleCampaignSourceClick}
              >
                {!campaignSourcesLoaded && (
                  <option value={deal.campaignSourceId}>{deal.campaignSource.name}</option>
                )}
                {campaignSources.map(campaignSource => (
                  <option key={campaignSource.dealCampaignSourceId} value={campaignSource.dealCampaignSourceId}>
                    {campaignSource.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="mb-4">
              <div className="d-flex flex-wrap justify-content-between mb-2">
                <h5 className="mb-0 text-body-highlight me-2">Deal Type</h5>
                <Link to="#!" className="fw-bold fs-9" onClick={handleDealTypeClick}>
                  Add new
                </Link>
              </div>
              <Form.Select
                value={selectedDealTypeId}
                onChange={(e) => handleSelectChange(e, 'typeId', setSelectedDealTypeId)}
                onClick={handleDealTypeClick}
              >
                {!dealTypesLoaded && (
                  <option value={deal.typeId}>{deal.type.name}</option>
                )}
                {dealTypes.map(dealType => (
                  <option key={dealType.dealTypeId} value={dealType.dealTypeId}>
                    {dealType.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DealsOtherInformation;
