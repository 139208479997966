import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { authService } from 'services/api';
import { useAuth } from 'providers/AuthProvider';

const SignInForm = ({ layout }: { layout: string }) => {
  const [formData, setFormData] = useState({
    instance: '',
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const { login } = useAuth();

  useEffect(() => {
    const savedInstance = localStorage.getItem('instance');
    if (savedInstance) {
      setFormData(prev => ({ ...prev, instance: savedInstance }));
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await authService.login(formData);
      login(response.token, response.userData);
      
      localStorage.setItem('instance', formData.instance);
    } catch (error) {
      setError('Credenciais inválidas');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <div className="alert alert-danger">{error}</div>}
      
      <Form.Group className="mb-3">
        <Form.Label>Instância</Form.Label>
        <Form.Control
          type="text"
          value={formData.instance}
          onChange={e => setFormData(prev => ({ ...prev, instance: e.target.value }))}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          value={formData.email}
          onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Senha</Form.Label>
        <Form.Control
          type="password"
          value={formData.password}
          onChange={e => setFormData(prev => ({ ...prev, password: e.target.value }))}
          required
        />
      </Form.Group>

      <button type="submit" className="btn btn-primary w-100">
        Entrar
      </button>
    </Form>
  );
};

export default SignInForm;
