import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Card, Dropdown, Form, Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { useAuth } from 'providers/AuthProvider';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const { logout, userData } = useAuth();
  const navigate = useNavigate();

  const [navItems] = useState([
    {
      label: 'Profile',
      icon: 'user',
      onClick: () => navigate('/apps/panel/user-profile')
    },
    {
      label: 'Dashboard',
      icon: 'pie-chart',
      onClick: () => navigate('/dashboard')
    },
    {
      label: 'Posts & Activity',
      icon: 'lock',
      onClick: () => {}
    },
    {
      label: 'Settings & Privacy',
      icon: 'settings',
      onClick: () => {}
    },
    {
      label: 'Help Center',
      icon: 'help-circle',
      onClick: () => {}
    },
    {
      label: 'Language',
      icon: 'globe',
      onClick: () => {}
    }
  ]);

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar 
              src={userData?.profileImage} 
              size="xl" 
              className="rounded-circle"
              fallback={userData?.fullName} 
              userId={userData?.userId}
            />
            <h6 className="text-body-emphasis">{userData?.fullName}</h6>
          </div>
          <div className="mb-3 mx-3">
            <Form.Control
              type="text"
              placeholder="Update your status"
              size="sm"
            />
          </div>
          <div style={{ height: '10rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link 
                      onClick={item.onClick}
                      className="px-3"
                      style={{ cursor: 'pointer' }}
                    >
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-body"
                      />
                      <span className="text-body-highlight">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top border-translucent">
          <Nav className="nav flex-column my-3">
            <Nav.Item>
              <Nav.Link href="#!" className="px-3">
                <FeatherIcon
                  icon="user-plus"
                  size={16}
                  className="me-2 text-body"
                />
                <span>Add another account</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <hr />
          <div className="px-3">
            <button
              onClick={logout}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Sign out
            </button>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            <Link className="text-body-quaternary me-1" to="#!">
              Privacy policy
            </Link>
            •
            <Link className="text-body-quaternary mx-1" to="#!">
              Terms
            </Link>
            •
            <Link className="text-body-quaternary ms-1" to="#!">
              Cookies
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
