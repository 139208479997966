import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { userBreadcrumbItems } from 'data/usersData';
import UsersTable, {
  usersTablecolumns,
  ExtendedUser
} from 'components/tables/UsersTable';
import { faFileExport, faPlus, faEdit, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';
import { dealFormService, User } from 'services/api';
import AddUserModal from 'components/modules/crm/users/AddUserModal';
import EditUserModal from 'components/modals/EditUserModal';
import DeleteConfirmationModal from 'components/modals/DeleteConfirmationModal';
import ActivateConfirmationModal from 'components/modals/ActivateConfirmationModal';

const Users = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<ExtendedUser | null>(null);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [userToEdit, setUserToEdit] = useState<ExtendedUser | null>(null);

  const fetchUsers = async () => {
    try {
      const response = await dealFormService.getUsers();
      setUsers(response);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const table = useAdvanceTable({
    data: users as any,
    columns: usersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const selectedUsers = table.getSelectedRowModel().rows;

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleAddUserClick = () => {
    setShowAddUserModal(true);
  };

  const handleEditUserClick = () => {
    if (selectedUsers.length === 1) {
      setUserToEdit(selectedUsers[0].original as ExtendedUser);
      setShowEditUserModal(true);
    }
  };

  const handleDeleteUserClick = () => {
    if (selectedUsers.length === 1) {
      setUserToDelete(selectedUsers[0].original as ExtendedUser);
      setShowDeleteConfirmationModal(true);
    }
  };

  const handleActivateUserClick = () => {
    if (selectedUsers.length === 1) {
      const selectedUser = selectedUsers[0].original as ExtendedUser;
      setSelectedUserId(selectedUser.userId);
      setShowActivateModal(true);
    }
  };

  const updateUsersAndResetSelection = async () => {
    await fetchUsers();
    table.resetRowSelection();
  };

  const handleConfirmDelete = async () => {
    if (userToDelete) {
      try {
        await dealFormService.deleteUser(userToDelete.userId);
        await updateUsersAndResetSelection();
      } catch (error) {
        console.error('Erro ao excluir usuário:', error);
      } finally {
        setShowDeleteConfirmationModal(false);
        setUserToDelete(null);
      }
    }
  };

  const handleConfirmActivate = async () => {
    if (selectedUserId) {
      try {
        await dealFormService.activateUser(selectedUserId);
        await updateUsersAndResetSelection();
      } catch (error) {
        console.error('Erro ao ativar usuário:', error);
      } finally {
        setShowActivateModal(false);
      }
    }
  };

  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const isAdminOrSuperAdmin = userData.accessType === 'admin' || userData.accessType === 'SuperAdmin';

  return (
    <div>
      <PageBreadcrumb items={userBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Users</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search users"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                {isAdminOrSuperAdmin && (
                  <>
                    <Button variant="primary" onClick={handleAddUserClick}>
                      <FontAwesomeIcon icon={faPlus} className="me-2" />
                      Adicionar
                    </Button>
                    <Button
                      variant="warning"
                      onClick={handleEditUserClick}
                      className="ms-2"
                      disabled={selectedUsers.length !== 1}
                    >
                      <FontAwesomeIcon icon={faEdit} className="me-2" />
                      Editar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleDeleteUserClick}
                      className="ms-2"
                      disabled={selectedUsers.length !== 1}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                      Excluir
                    </Button>
                    <Button
                      variant="success"
                      onClick={handleActivateUserClick}
                      className="ms-2"
                      disabled={selectedUsers.length !== 1}
                    >
                      <FontAwesomeIcon icon={faCheck} className="me-2" />
                      Ativar
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <UsersTable />
          </div>
        </AdvanceTableProvider>
      </div>

      <AddUserModal 
        show={showAddUserModal} 
        handleClose={() => setShowAddUserModal(false)} 
        onUserAdded={fetchUsers}
        fetchUsers={fetchUsers}
      />
      <DeleteConfirmationModal
        show={showDeleteConfirmationModal}
        handleClose={() => setShowDeleteConfirmationModal(false)}
        handleConfirm={handleConfirmDelete}
        title="Confirmar Exclusão"
        message={`Tem certeza de que deseja excluir o usuário?`}
      />
      <ActivateConfirmationModal
        show={showActivateModal}
        handleClose={() => setShowActivateModal(false)}
        handleConfirm={handleConfirmActivate}
        title="Confirmar Ativação"
        message="Tem certeza de que deseja ativar este usuário?"
      />
      <EditUserModal
        show={showEditUserModal}
        handleClose={() => setShowEditUserModal(false)}
        userId={userToEdit ? userToEdit.userId : ''}
        onUserUpdated={fetchUsers}
        resetSelection={table.resetRowSelection}
      />
    </div>
  );
};

export default Users;
