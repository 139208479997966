import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Modal, Form, Toast, Spinner, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { customerService } from 'services/api';
import DatePicker from 'components/base/DatePicker';

interface AddLeadModalProps {
  show: boolean;
  handleClose: () => void;
  onLeadAdded: () => void;
}

const AddLeadModal = ({ show, handleClose, onLeadAdded }: AddLeadModalProps) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    birthdate: '',
    company: '',
    jobTitle: '',
    leadSource: '',
    status: 'New',
    isActive: true,
    description: '',
    photoUrl: '',
    email: '',
    phone: '',
    document: '',
    isCompany: false,
    annualRevenue: 0,
    numberOfEmployees: 0
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    if (!show) {
      setFormData({
        firstName: '',
        lastName: '',
        birthdate: '',
        company: '',
        jobTitle: '',
        leadSource: '',
        status: 'New',
        isActive: true,
        description: '',
        photoUrl: '',
        email: '',
        phone: '',
        document: '',
        isCompany: false,
        annualRevenue: 0,
        numberOfEmployees: 0
      });
    }
  }, [show]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async () => {
    if (!formData.firstName || !formData.lastName || !formData.email) {
      setToastMessage('Nome, sobrenome e email são obrigatórios.');
      setShowToast(true);
      return;
    }

    try {
      setIsSubmitting(true);
      await customerService.addLead(formData);
      setToastMessage('Lead adicionado com sucesso!');
      setShowToast(true);
      onLeadAdded();
      handleClose();
    } catch (error) {
      console.error('Erro ao adicionar lead:', error);
      setToastMessage('Erro ao adicionar lead. Tente novamente.');
      setShowToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={show}
        centered
        backdrop="static"
        onHide={handleClose}
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">Adicionar Lead</h3>
          <Button
            variant="phoenix-secondary"
            className="ms-auto"
            onClick={handleClose}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Form>
            <Row className="g-4">
              <Col lg={6} className="d-flex flex-column gap-4">
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Nome</Form.Label>
                  <Form.Control
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Digite o nome"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Email</Form.Label>
                  <Form.Control
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    type="email"
                    placeholder="Digite o email"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Numero do documento (CPF)</Form.Label>
                  <Form.Control
                    name="document"
                    value={formData.document}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Digite o documento"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Empresa</Form.Label>
                  <Form.Control
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Digite o nome da empresa"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Fonte do Lead</Form.Label>
                  <Form.Control
                    name="leadSource"
                    value={formData.leadSource}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Digite a fonte do lead"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                  >
                    <option value="New">New</option>
                    <option value="Suspended">Suspended</option>
                    <option value="Ongoing">Ongoing</option>
                    <option value="Current">Current</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Descrição</Form.Label>
                  <Form.Control
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    as="textarea"
                    placeholder="Digite uma descrição"
                  />
                </Form.Group>
              </Col>
              <Col lg={6} className="d-flex flex-column gap-4">
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Sobrenome</Form.Label>
                  <Form.Control
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Digite o sobrenome"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Telefone</Form.Label>
                  <Form.Control
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Digite o telefone"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Data de Nascimento</Form.Label>
                  <DatePicker
                    options={{
                      enableTime: true,
                      dateFormat: 'd/m/Y H:i',
                      time_24hr: true,
                      onChange: (selectedDates: Date[]) => {
                        if (selectedDates[0]) {
                          const formattedDate = selectedDates[0].toLocaleString('pt-BR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                          });
                          setFormData(prev => ({
                            ...prev,
                            birthdate: formattedDate
                          }));
                        }
                      }
                    }}
                    placeholder="Selecione a data e hora"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">Cargo</Form.Label>
                  <Form.Control
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Digite o cargo"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">URL da Foto</Form.Label>
                  <Form.Control
                    name="photoUrl"
                    value={formData.photoUrl}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Digite a URL da foto"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-danger px-3 my-0"
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Cancelar
          </Button>
          {isSubmitting ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Adicionando...
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Adicionar Lead
            </Button>
          )}
        </Modal.Footer>
        <Toast 
          show={showToast} 
          onClose={() => setShowToast(false)}
          autohide
          delay={3000}
          style={{ position: 'fixed', bottom: 20, right: 20 }}
        >
          <Toast.Header>
            <strong className="me-auto">Notificação</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </Modal>
    </>
  );
};

export default AddLeadModal; 