import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import { Col, Form, Modal, Row, Spinner, Toast } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { dealFormService, dealService } from 'services/api';
import { useDealsContext } from 'providers/CrmDealsProvider';

interface AddDealModalProps {
  show: boolean;
  handleClose: () => void;
}

interface User {
  userId: string;
  fullName: string;
  // ... outros campos
}

interface DealCategory {
  dealCategoryId: string;
  name: string;
}

interface CampaignSource {
  dealCampaignSourceId: string;
  name: string;
}

interface DealType {
  dealTypeId: string;
  name: string;
}

interface LeadSource {
  leadSourceId: string;
  name: string;
}

interface FormData {
  title: string;
  contactName: string;
  dealCode: string;
  categoryId: string;
  leadSourceId: string;
  campaignSourceId: string;
  typeId: string;
  industryId: string;
  responsibleUserId: string;
  totalValue: number;
  priority: string;
  deliveryDate: string;
  probability: number;
  stage: string;
  phoneNumber?: string;
  emailAddress?: string;
}

interface Industry {
  dealIndustryId: string;
  name: string;
}

const AddDealModal = ({ show, handleClose }: AddDealModalProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [dealCategories, setDealCategories] = useState<DealCategory[]>([]);
  const [campaignSources, setCampaignSources] = useState<CampaignSource[]>([]);
  const [dealTypes, setDealTypes] = useState<DealType[]>([]);
  const [leadSources, setLeadSources] = useState<LeadSource[]>([]);
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const { selectedColumnId, boardId, refreshBoard } = useDealsContext();
  
  const [formData, setFormData] = useState<FormData>({
    title: '',
    contactName: '',
    dealCode: '',
    categoryId: '',
    leadSourceId: '',
    campaignSourceId: '',
    typeId: '',
    industryId: '',
    responsibleUserId: '',
    totalValue: 0,
    priority: 'Medium',
    deliveryDate: '',
    probability: 0,
    stage: 'New',
    phoneNumber: '',
    emailAddress: ''
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      if (!selectedColumnId) {
        setToastMessage('Erro: Coluna não selecionada');
        setShowToast(true);
        setIsSubmitting(false);
        return;
      }

      if (!formData.deliveryDate) {
        setToastMessage('Por favor, preencha a data de entrega');
        setShowToast(true);
        setIsSubmitting(false);
        return;
      }

      const dealData = {
        ...formData,
        columnId: selectedColumnId,
        boardId: boardId,
        companyId: null,
        industryId: formData.industryId,
        deliveryDate: formData.deliveryDate ? 
          new Date(formData.deliveryDate.replace(/(\d{2})\/(\d{2})\/(\d{4}),\s*(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:00')).toISOString() : '',
        totalValue: Number(formData.totalValue),
        probability: Number(formData.probability)
      };

      await dealService.createDeal(dealData);
      await refreshBoard();
      handleClose();
      setToastMessage('Deal criado com sucesso!');
      setShowToast(true);
    } catch (error) {
      console.error('Erro ao criar deal:', error);
      setToastMessage('Erro ao criar deal');
      setShowToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (show) {
      loadFormData();
    }
  }, [show]);

  const loadFormData = async () => {
    try {
      setLoading(true);
      const [
        usersData,
        categoriesData,
        campaignData,
        typesData,
        sourcesData,
        industriesData
      ] = await Promise.all([
        dealFormService.getUsers(),
        dealFormService.getDealCategories(),
        dealFormService.getCampaignSources(),
        dealFormService.getDealTypes(),
        dealFormService.getLeadSources(),
        dealFormService.getDealIndustries()
      ]);

      setUsers(usersData);
      setDealCategories(categoriesData);
      setCampaignSources(campaignData);
      setDealTypes(typesData);
      setLeadSources(sourcesData);
      setIndustries(industriesData);
    } catch (error) {
      console.error('Erro ao carregar dados do formulário:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={show}
        centered
        backdrop="static"
        onHide={handleClose}
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">Deal Informations</h3>
          <Button
            variant="phoenix-secondary"
            className="ms-auto"
            onClick={handleClose}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Row className="g-4">
            <Col lg={6} className="d-flex flex-column gap-4">
              <Form.Group>
                <Form.Label className="form-label-header mb-2">
                  Deal Owner
                </Form.Label>
                <Form.Select
                  name="responsibleUserId"
                  value={formData.responsibleUserId}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {users.map(user => (
                    <option key={user.userId} value={user.userId}>
                      {user.fullName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label className="form-label-header mb-2">
                  Deal Name
                </Form.Label>
                <Form.Control
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Enter deal name"
                />
              </Form.Group>

              <Row className="g-3">
                <Form.Group as={Col} lg={12} xl={6}>
                  <Form.Label className="form-label-header mb-2">
                    Deal Amount
                  </Form.Label>
                  <Row className="g-2">
                    <Col xs={12}>
                      <Form.Control
                        name="totalValue"
                        value={formData.totalValue}
                        onChange={handleInputChange}
                        type="number"
                        placeholder="$ Enter amount"
                        className="flex-1"
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} lg={12} xl={6}>
                  <Form.Label className="form-label-header mb-2">
                    Deal Code
                  </Form.Label>
                  <Form.Control
                    name="dealCode"
                    value={formData.dealCode}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Enter deals code"
                  />
                </Form.Group>
              </Row>

              <Form.Group>
                <div className="mb-2">
                  <Form.Label className="form-label-header mb-0">
                    Deal Type
                  </Form.Label>
                  <Button
                    variant="link"
                    className="p-0 ms-3"
                    startIcon={<FontAwesomeIcon icon={faPlus} className="" />}
                  >
                    Add new
                  </Button>
                </div>
                <Form.Select 
                  name="typeId"
                  value={formData.typeId}
                  onChange={handleInputChange}
                  className="form-select"
                >
                  <option value="">Select</option>
                  {dealTypes.map(type => (
                    <option key={type.dealTypeId} value={type.dealTypeId}>
                      {type.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <div className="mb-2">
                  <Form.Label className="form-label-header mb-0">
                    Category
                  </Form.Label>
                  <Button
                    variant="link"
                    className="p-0 ms-3"
                    startIcon={<FontAwesomeIcon icon={faPlus} className="" />}
                  >
                    Add new
                  </Button>
                </div>
                <Form.Select 
                  name="categoryId"
                  value={formData.categoryId}
                  onChange={handleInputChange}
                  className="form-select"
                >
                  <option value="">Select</option>
                  {dealCategories.map(category => (
                    <option key={category.dealCategoryId} value={category.dealCategoryId}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Row className="g-3">
                <Form.Group as={Col} xs={6}>
                  <Form.Label className="form-label-header mb-2">
                    Stage
                  </Form.Label>
                  <Form.Select className="form-select">
                    <option>Select</option>
                    <option>New</option>
                    <option>In Progress</option>
                    <option>Pending</option>
                    <option>Canceled</option>
                    <option>Completed</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                  <Form.Label className="form-label-header mb-2">
                    Priority
                  </Form.Label>
                  <Form.Select>
                    <option>Urgent</option>
                    <option>High</option>
                    <option>Medium</option>
                    <option>Low</option>
                  </Form.Select>
                </Form.Group>
              </Row>
            </Col>

            <Col lg={6} className="d-flex flex-column gap-4">
              <Form.Group>
                <Form.Label className="form-label-header mb-2">
                  Contact Name
                </Form.Label>
                <Form.Control
                  name="contactName"
                  value={formData.contactName}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Enter contact name"
                />
              </Form.Group>

              <Row className="g-3">
                <Form.Group as={Col} lg={12} xl={6}>
                  <Form.Label className="form-label-header mb-2">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Enter phone number"
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12} xl={6}>
                  <Form.Label className="form-label-header mb-2">
                    Email Address
                  </Form.Label>
                  <Form.Control
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Enter email address"
                  />
                </Form.Group>
              </Row>

              <Form.Group>
                <div className="mb-2">
                  <Form.Label className="form-label-header mb-0">
                    Indústria
                  </Form.Label>
                  <Button
                    variant="link"
                    className="p-0 ms-3"
                    startIcon={<FontAwesomeIcon icon={faPlus} className="" />}
                  >
                    Add new
                  </Button>
                </div>
                <Form.Select 
                  name="industryId"
                  value={formData.industryId}
                  onChange={handleInputChange}
                  className="form-select"
                >
                  <option value="">Selecione</option>
                  {industries.map(industry => (
                    <option key={industry.dealIndustryId} value={industry.dealIndustryId}>
                      {industry.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <div className="mb-2">
                  <Form.Label className="form-label-header mb-0">
                    Lead Source
                  </Form.Label>
                  <Button
                    variant="link"
                    className="p-0 ms-3"
                    startIcon={<FontAwesomeIcon icon={faPlus} className="" />}
                  >
                    Add new
                  </Button>
                </div>
                <Form.Select 
                  name="leadSourceId"
                  value={formData.leadSourceId}
                  onChange={handleInputChange}
                  className="form-select"
                >
                  <option value="">Select</option>
                  {leadSources.map(source => (
                    <option key={source.leadSourceId} value={source.leadSourceId}>
                      {source.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <div className="mb-2">
                  <Form.Label className="form-label-header mb-0">
                    Campaign Source
                  </Form.Label>
                  <Button
                    variant="link"
                    className="p-0 ms-3"
                    startIcon={<FontAwesomeIcon icon={faPlus} className="" />}
                  >
                    Add new
                  </Button>
                </div>
                <Form.Select 
                  name="campaignSourceId"
                  value={formData.campaignSourceId}
                  onChange={handleInputChange}
                  className="form-select"
                >
                  <option value="">Select</option>
                  {campaignSources.map(source => (
                    <option key={source.dealCampaignSourceId} value={source.dealCampaignSourceId}>
                      {source.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Row className="g-3">
                <Form.Group as={Col} lg={12} xl={6}>
                  <Form.Label className="form-label-header mb-2">
                    Probability (%)
                  </Form.Label>
                  <Form.Control
                    name="probability"
                    value={formData.probability}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Enter value"
                  />
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                  <Form.Label className="form-label-header mb-2">
                    Delivery Date
                  </Form.Label>
                  <DatePicker
                    options={{
                      enableTime: true,
                      dateFormat: 'd/m/Y H:i',
                      time_24hr: true,
                      onChange: (selectedDates: Date[]) => {
                        if (selectedDates[0]) {
                          const formattedDate = selectedDates[0].toLocaleString('pt-BR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                          });
                          setFormData(prev => ({
                            ...prev,
                            deliveryDate: formattedDate
                          }));
                        }
                      }
                    }}
                    placeholder="Select date & time"
                  />
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-danger px-3 my-0"
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          {isSubmitting ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Criando...
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Create Deal
            </Button>
          )}
        </Modal.Footer>
        <Toast 
          show={showToast} 
          onClose={() => setShowToast(false)}
          autohide
          delay={3000}
          style={{ position: 'fixed', bottom: 20, right: 20 }}
        >
          <Toast.Header>
            <strong className="me-auto">Notificação</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </Modal>
    </>
  );
};

export default AddDealModal;
