import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import { User } from 'services/api';

// Estenda a interface existente
export interface ExtendedUser extends User {
  userId: string;
  fullName: string;
  email: string;
  isActive: boolean;
  accessType: 'user' | 'admin' | 'superadmin';
}

export const usersTablecolumns: ColumnDef<ExtendedUser>[] = [
  {
    accessorKey: 'name',
    header: 'Nome',
    cell: ({ row }) => {
      const user = row.original as ExtendedUser;
      return (
        <div className="d-flex align-items-center">
          <div className="d-none d-md-block">
            <Avatar
              size="l"
              className="me-2"
              fallback={user.fullName}
              userId={user.userId}
            />
          </div>
          <span>{user.fullName}</span>
        </div>
      );
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'accessType',
    header: 'Tipo de Acesso',
    cell: ({ row }) => {
      const accessTypeMap: Record<'user' | 'admin' | 'superadmin', string> = {
        user: 'Usuário',
        admin: 'Administrador',
        superadmin: 'SuperAdmin'
      };
      const accessType = row.original.accessType.toLowerCase() as 'user' | 'admin' | 'superadmin';
      return accessTypeMap[accessType] || row.original.accessType;
    }
  },
  {
    accessorKey: 'isActive',
    header: 'Status',
    cell: ({ row }) => (
      <span className={`badge ${row.original.isActive ? 'bg-success' : 'bg-danger'}`}>
        {row.original.isActive ? 'Ativo' : 'Inativo'}
      </span>
    )
  },
  {
    accessorKey: 'lastLogin',
    header: 'Último Login',
    cell: ({ row }) => row.original.lastLogin 
      ? new Date(row.original.lastLogin).toLocaleDateString('pt-BR')
      : '-'
  }
];

const UsersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UsersTable;
