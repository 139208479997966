import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'providers/AuthProvider';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/pages/authentication/simple/sign-in');
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? <>{children}</> : null;
};

export default PrivateRoute; 