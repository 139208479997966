import {
  faCircleInfo,
  faEnvelope,
  faPhone,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Row } from 'react-bootstrap';
import { useAuth } from 'providers/AuthProvider';

const SettingsPersonalInfo = () => {
  const { userData } = useAuth();

  return (
    <div className="mb-6">
      <h4 className="mb-4">Personal Information</h4>
      <Row className="g-3">
        <Col xs={12} sm={6}>
          <div className="form-icon-container">
            <Form.Floating>
              <Form.Control
                id="fullName"
                type="text"
                placeholder="Full Name"
                className="form-icon-input"
                defaultValue={userData?.fullName || ''}
              />
              <label
                htmlFor="fullName"
                className="form-icon-label text-body-tertiary"
              >
                FULL NAME
              </label>
            </Form.Floating>
            <FontAwesomeIcon
              icon={faUser}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <div className="form-icon-container">
            <Form.Floating>
              <Form.Control
                id="email"
                type="email"
                placeholder="Enter your email"
                className="form-icon-input"
                defaultValue={userData?.email || ''}
                disabled
              />
              <label
                htmlFor="email"
                className="form-icon-label text-body-tertiary"
              >
                ENTER YOUR EMAIL
              </label>
            </Form.Floating>
            <FontAwesomeIcon
              icon={faEnvelope}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <div className="form-icon-container">
            <Form.Floating>
              <Form.Control
                id="organization"
                type="text"
                placeholder="Organization"
                className="form-icon-input"
                defaultValue={userData?.Organization.name || ''}
                disabled
              />
              <label
                htmlFor="organization"
                className="form-icon-label text-body-tertiary"
              >
                ORGANIZATION
              </label>
            </Form.Floating>
            <FontAwesomeIcon
              icon={faPhone}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <div className="form-icon-container">
            <Form.Floating>
              <Form.Control
                id="info"
                as="textarea"
                style={{ height: '115px' }}
                type="text"
                placeholder="info"
                className="form-icon-input"
                defaultValue={userData?.accessType || ''}
                disabled
              />
              <label
                htmlFor="lastName"
                className="form-icon-label text-body-tertiary"
              >
                ACCESS TYPE
              </label>
            </Form.Floating>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SettingsPersonalInfo;
