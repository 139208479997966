import React, { CSSProperties, ChangeEvent, useState, useRef } from 'react';
import Avatar, { Size, Status } from 'components/base/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'providers/AuthProvider';
import { userService } from 'services/api';
import { createFileUploadPayload } from 'utils/fileUpload';

interface AvatarUploadProps {
  size: Size;
  src: string;
  className?: string;
  status?: Status;
  thumbnail?: boolean;
  onChange?: (newAvatarUrl: string) => void;
  imageClassName?: string;
}

const AvatarUpload = ({
  size,
  src,
  className,
  status,
  thumbnail,
  onChange,
  imageClassName,
}: AvatarUploadProps) => {
  const { userData, updateUserData } = useAuth();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !userData?.userId) return;

    const previousImage = selectedImage;
    setSelectedImage(file);
    
    try {
      const payload = await createFileUploadPayload(file);
      const response = await userService.updateUserAvatar(userData.userId, payload);

      if (response?.filePath && userData) {
        const updatedUserData = {
          ...userData,
          profileImage: response.filePath
        };
        
        updateUserData(updatedUserData);
        onChange?.(response.filePath);
      }
    } catch (error) {
      console.error('Error uploading avatar:', error);
      setSelectedImage(null);
      e.target.value = '';
    }
  };

  return (
    <div className={classNames('position-relative', className)}>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        className="d-none"
        onChange={handleFileChange}
      />
      <Avatar
        size={size}
        status={status}
        src={selectedImage ? URL.createObjectURL(selectedImage) : src}
        thumbnail={thumbnail}
        imageClassName={imageClassName}
      />
      <label
        htmlFor={inputRef.current?.id}
        className="cursor-pointer mb-0 position-absolute top-0 start-0 w-100 h-100"
        onClick={() => inputRef.current?.click()}
      >
        <div
          className="h-100 w-100 bg-black position-absolute top-0 rounded-circle justify-content-center align-items-center hover-actions"
          data-bs-theme="light"
          style={{ '--phoenix-bg-opacity': 0.56 } as CSSProperties}
        >
          <FontAwesomeIcon icon={faCamera} className="text-light w-30 h-30" />
        </div>
      </label>
    </div>
  );
};

export default AvatarUpload;
