import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Modal, Form, Toast, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { dealFormService } from 'services/api';

interface EditUserModalProps {
  show: boolean;
  handleClose: () => void;
  userId: string;
  onUserUpdated: () => void;
  resetSelection: () => void;
}

const EditUserModal = ({ show, handleClose, userId, onUserUpdated, resetSelection }: EditUserModalProps) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contact: '',
    profileImage: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    if (show) {
      loadUserData();
    }
  }, [show]);

  const loadUserData = async () => {
    try {
      const userData = await dealFormService.getUserById(userId);
      setFormData({
        fullName: userData.fullName || '',
        email: userData.email || '',
        contact: userData.contact || '',
        profileImage: userData.profileImage || ''
      });
    } catch (error) {
      console.error('Erro ao carregar dados do usuário:', error);
      setToastMessage('Erro ao carregar dados do usuário.');
      setShowToast(true);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    if (!formData.fullName || !formData.email) {
      setToastMessage('Email e nome completo são obrigatórios.');
      setShowToast(true);
      return;
    }

    try {
      setIsSubmitting(true);
      await dealFormService.updateUser(userId, formData);
      setToastMessage('Usuário atualizado com sucesso!');
      setShowToast(true);
      onUserUpdated();
      resetSelection();
      handleClose();
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      setToastMessage('Erro ao atualizar usuário. Tente novamente.');
      setShowToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        centered
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header>
          <h3 className="mb-0">Editar Usuário</h3>
          <Button
            variant="phoenix-secondary"
            className="ms-auto"
            onClick={handleClose}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nome Completo</Form.Label>
              <Form.Control
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                type="text"
                placeholder="Digite o nome completo"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                type="email"
                placeholder="Digite o email"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Contato</Form.Label>
              <Form.Control
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                type="text"
                placeholder="Digite o número de contato"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Imagem de Perfil</Form.Label>
              <Form.Control
                name="profileImage"
                value={formData.profileImage}
                onChange={handleInputChange}
                type="text"
                placeholder="URL da imagem de perfil"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-danger" onClick={handleClose}>
            Cancelar
          </Button>
          {isSubmitting ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Atualizando...
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSubmit}>
              Atualizar Usuário
            </Button>
          )}
        </Modal.Footer>
        <Toast 
          show={showToast} 
          onClose={() => setShowToast(false)}
          autohide
          delay={3000}
          style={{ position: 'fixed', bottom: 20, right: 20 }}
        >
          <Toast.Header>
            <strong className="me-auto">Notificação</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </Modal>
    </>
  );
};

export default EditUserModal; 