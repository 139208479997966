import { useEffect, useState, useMemo, useCallback } from 'react';
import { EventClickArg } from '@fullcalendar/core';
import CalendarAddNewEventModal from 'components/modals/CalendarAddNewEventModal';
import CalendarProvider, {
  useCalendarContext
} from 'providers/CalendarProvider';
import CalendarTop from './CalendarTop';
import CalendarHeader from './CalendarHeader';
import CalendarEventModal from 'components/modals/CalendarEventModal';
import { HANDLE_SELECT, SET_CALENDAR_STATE } from 'reducers/CalendarReducer';
import FullCalendar from 'components/base/FullCalendar';
import { calendarService } from 'services/api';
import { CalendarEvent } from 'data/calendarEvents';
import { Row, Col, Form } from 'react-bootstrap';
import logger from 'utils/logger';
import CalendarAddNewModal from 'components/modals/CalendarAddNewModal';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const index = () => {
  return (
    <CalendarProvider>
      <Calendar />
    </CalendarProvider>
  );
};

const Calendar = () => {
  const { calendarApi, calendarDispatch } = useCalendarContext();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [calendars, setCalendars] = useState<{ calendarId: string, colorId: string, name: string }[]>([]);
  const [selectedCalendars, setSelectedCalendars] = useState<Set<string>>(new Set());
  const [showAddCalendarModal, setShowAddCalendarModal] = useState(false);

  const fetchCalendarEvents = useCallback(async (startDate: string, endDate: string) => {
    try {
      const response = await calendarService.listCalendars(startDate, endDate);
      
      const transformedEvents = response.flatMap((calendar: any) => 
        calendar.events.map((event: any) => ({
          id: event.eventId,
          title: event.title,
          start: event.startAt,
          end: event.endAt,
          description: event.description,
          className: `text-${calendar.colorId}`,
          allDay: event.allDay,
          calendarId: event.calendarId
        }))
      );

      logger.log(JSON.stringify(transformedEvents));

      setEvents(transformedEvents);
      setCalendars(response);
      setSelectedCalendars(new Set(response.map((calendar: any) => calendar.calendarId)));
    } catch (error) {
      logger.error('Erro ao buscar eventos do calendário:', error);
    }
  }, []);

  useEffect(() => {
    if (calendarApi) {
      const startDate = calendarApi.view.activeStart.toISOString();
      const endDate = calendarApi.view.activeEnd.toISOString();
      fetchCalendarEvents(startDate, endDate);
    }
  }, [calendarApi, fetchCalendarEvents]);

  const handleEventClick = useCallback((info: EventClickArg) => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      calendarDispatch({
        type: SET_CALENDAR_STATE,
        payload: {
          selectedEvent: info.event
        }
      });
    }
  }, [calendarDispatch]);

  const handleCheckboxChange = useCallback((calendarId: string) => {
    setSelectedCalendars(prevSelected => {
      const newSelected = new Set(prevSelected);
      newSelected.has(calendarId) ? newSelected.delete(calendarId) : newSelected.add(calendarId);
      return newSelected;
    });
  }, []);

  const filteredEvents = useMemo(() => 
    events.filter((event: any) => selectedCalendars.has(event.calendarId))
      .map((event: any) => {
        const calendar = calendars.find((c: any) => c.calendarId === event.calendarId);
        return {
          ...event,
          backgroundColor: calendar?.colorId,
          borderColor: calendar?.colorId
        };
      }), [events, selectedCalendars, calendars]);

  return (
    <div>
      <CalendarTop />
      <CalendarHeader />
      <Row>
        <Col xs={12} md={2}>
          <Row>
            <span className="fw-bold fs-7">
              My Calendars
              <Link to="#!" className="ms-2">
                <FontAwesomeIcon icon={faPlus} onClick={() => setShowAddCalendarModal(true)} />
              </Link>
            </span>
          </Row>
          <Row>
          {calendars.map(({ calendarId, colorId, name }) => (
            <Form.Check
              id={`calendar-${calendarId}`}
              key={calendarId}
            >
              <Form.Check.Input 
                type="checkbox"
                className={`bg-${colorId} border-${colorId}-soft`}
                onChange={() => handleCheckboxChange(calendarId)}
                checked={selectedCalendars.has(calendarId)}
              />
              <Form.Check.Label className="fs-8">
                {name}
              </Form.Check.Label>
            </Form.Check>
          ))}
          </Row>
        </Col>
        <Col xs={12} md={10}>
          <FullCalendar
            height={800}
            select={info => {
              calendarDispatch({
                type: HANDLE_SELECT,
                payload: info
              });
            }}
            events={filteredEvents}
            eventClick={handleEventClick}
            datesSet={(dateInfo) => {
              logger.info('Calendar view changed:', dateInfo);
              fetchCalendarEvents(dateInfo.startStr, dateInfo.endStr);
            }}
          />
        </Col>
      </Row>
      <CalendarEventModal />
      <CalendarAddNewEventModal />
      <CalendarAddNewModal
        show={showAddCalendarModal}
        handleClose={() => setShowAddCalendarModal(false)}
      />
    </div>
  );
};

export default index;
